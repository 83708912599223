import {Injectable} from "@angular/core";
import {Observable, tap} from "rxjs";
import {AuthResponse} from "../model/responses";
import {UserService} from "./api/user.service";
import {AuthedUserService} from "./authed-user.service";
import {UserBasicInfo} from "../model/user";
import {GoogleLoginRequest} from "../model/requests";
import {SocialAuthService} from "@abacritt/angularx-social-login";
import {NavigationService} from "./ui/navigation.service";
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";

@Injectable()
export class LoginService {

    constructor(
        private localStorageService: AuthedUserService,
        private userService: UserService,
        private authService: SocialAuthService,
        private navigationService: NavigationService,
        private route: ActivatedRoute,
        private dialog: MatDialog
    ) {
        (window as any).googlePlayLoginCallback = (token: string) => {
            console.log('googlePlayLoginCallback')
            this.userService.googleLogin({idToken: token}).subscribe(response => {
                console.log('googlePlayLoginCallback response')
                this.onLogin(response)
            });
            return true
        }
    }

    public subscribeToSocialLogin() {
        const authSub = this.authService.authState.subscribe((user) => {
            if (user != null) {
                let googleLoginRequest: GoogleLoginRequest = {
                    idToken: user.idToken
                };
                this.userService.googleLogin(googleLoginRequest).subscribe(response => {
                    this.onLogin(response)
                    authSub.unsubscribe();
                });
            }
        });
    }

    public onLogin(authResponse: AuthResponse): Observable<UserBasicInfo> {
        const loginResult = this.localStorageService.setAuth(authResponse).pipe(
            tap(userData => {
                this.userService.updateUserGeoLocation({}).subscribe(_ => {
                });
                if (this.route.snapshot.queryParams['redirect']) {
                    this.navigationService.navigate([this.route.snapshot.queryParams['redirect']], true)
                } else if (this.navigationService.currentPath.startsWith('/login')) {
                    this.navigationService.navigate([''], true)
                } else if (this.dialog.openDialogs.length > 0) {
                    this.dialog.closeAll()
                    this.navigationService.navigate([''], true)
                }
            })
        );

        // Ensure that the token setting is always performed
        loginResult.subscribe()

        return loginResult
    }
}
